@import url(https://fonts.googleapis.com/css?family=Kanit);
html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Kanit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-height: 100vh;
  /* background: linear-gradient(90deg, #FEC32C 0%, #DB9D24 100%); */
  /* background: #FEC32C; */
  padding-bottom: calc(constant(safe-area-inset-bottom) + -15px);
  padding-bottom: calc(env(safe-area-inset-bottom) + -15px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
  border: none !important;
}

.card-body {
  padding: 1rem !important;
}


.App {
  font-family: 'Kanit', Tahoma, Geneva, Verdana, sans-serif;
  min-height: 100vh;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

