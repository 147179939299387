@import url('https://fonts.googleapis.com/css?family=Kanit');

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Kanit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-height: 100vh;
  /* background: linear-gradient(90deg, #FEC32C 0%, #DB9D24 100%); */
  /* background: #FEC32C; */
  padding-bottom: calc(constant(safe-area-inset-bottom) + -15px);
  padding-bottom: calc(env(safe-area-inset-bottom) + -15px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
  border: none !important;
}

.card-body {
  padding: 1rem !important;
}

